var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-container",
    { attrs: { fluid: "" } },
    [
      _c(
        "b-row",
        { staticClass: "mb-4" },
        [
          _c("b-col", [_c("h1", [_vm._v("Users")])]),
          _c(
            "b-col",
            { staticClass: "text-right", attrs: { cols: "4" } },
            [
              _c(
                "label",
                { staticClass: "mr-4", attrs: { for: "text-search" } },
                [_vm._v("Search")]
              ),
              _c("b-form-input", {
                staticClass: "inline mr-4",
                attrs: { id: "text-search", debounce: "800" },
                model: {
                  value: _vm.filter.q,
                  callback: function($$v) {
                    _vm.$set(_vm.filter, "q", $$v)
                  },
                  expression: "filter.q"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "b-row",
        { staticClass: "mt-3" },
        [
          _c(
            "b-col",
            [
              _c("rest-table", {
                attrs: {
                  endpoint: "users",
                  fields: _vm.fields,
                  filter: _vm.filter
                },
                scopedSlots: _vm._u([
                  {
                    key: "cell(Username)",
                    fn: function(data) {
                      return [
                        _c("strong", [_vm._v(_vm._s(data.item.Username))])
                      ]
                    }
                  },
                  {
                    key: "cell(LastLogin)",
                    fn: function(data) {
                      return [
                        _vm._v(
                          " " +
                            _vm._s(_vm._f("dateFormat")(data.item.LastLogin)) +
                            " "
                        )
                      ]
                    }
                  },
                  {
                    key: "cell(IsSuperUser)",
                    fn: function(data) {
                      return [
                        data.item.IsSuperUser
                          ? _c("span", [_vm._v("Yes")])
                          : _vm._e(),
                        !data.item.IsSuperUser
                          ? _c("span", [_vm._v("No")])
                          : _vm._e()
                      ]
                    }
                  },
                  {
                    key: "cell(IsActive)",
                    fn: function(data) {
                      return [
                        data.item.IsActive
                          ? _c("span", [_vm._v("Yes")])
                          : _vm._e(),
                        !data.item.IsActive
                          ? _c("span", [_vm._v("No")])
                          : _vm._e()
                      ]
                    }
                  },
                  {
                    key: "cell(actions)",
                    fn: function(data) {
                      return [
                        data.item.IsActive === 1
                          ? _c(
                              "router-link",
                              {
                                attrs: {
                                  to: {
                                    name: "impersonate",
                                    params: { id: data.item.UserAccountID }
                                  },
                                  title: "Impersonate"
                                }
                              },
                              [_c("b-icon-person-badge")],
                              1
                            )
                          : _vm._e()
                      ]
                    }
                  }
                ])
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }