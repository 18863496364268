<template>
  <b-container fluid>
    <b-row class="mb-4">
      <b-col>
        <h1>Users</h1>
      </b-col>

      <b-col cols="4" class="text-right">
        <label for="text-search" class="mr-4">Search</label>
        <b-form-input id="text-search" class="inline mr-4" v-model="filter.q" debounce="800" />
      </b-col>
    </b-row>

    <b-row class="mt-3">
      <b-col>
        <rest-table
            endpoint="users"
            :fields="fields"
            :filter="filter">

          <template v-slot:cell(Username)="data">
            <strong>{{ data.item.Username }}</strong>
          </template>

          <template v-slot:cell(LastLogin)="data">
            {{ data.item.LastLogin|dateFormat }}
          </template>

          <template v-slot:cell(IsSuperUser)="data">
            <span v-if="data.item.IsSuperUser">Yes</span>
            <span v-if="!data.item.IsSuperUser">No</span>
          </template>

          <template v-slot:cell(IsActive)="data">
            <span v-if="data.item.IsActive">Yes</span>
            <span v-if="!data.item.IsActive">No</span>
          </template>

          <template v-slot:cell(actions)="data">
            <router-link v-if="data.item.IsActive === 1" :to="{ name: 'impersonate', params: { id: data.item.UserAccountID } }" title="Impersonate">
              <b-icon-person-badge />
            </router-link>
          </template>
        </rest-table>
      </b-col>
    </b-row>  
  </b-container>
</template>

<script>
  export default {
    name: 'Users',
    components: {
      'rest-table': () => import('@/components/RestTable.vue'),
    },
    data () {
      return {
        filter: { q: '' },
        fields: [
          {
            key: 'EMail',
            label: 'E-mail',
            sortable: true,
          },
          {
            key: 'FullName',
            label: 'Full name',
            sortable: true,
          },
          {
            key: 'EMail',
            label: 'E-mail',
            sortable: true,
          },
          {
            key: 'IsSuperUser',
            label: 'SuperUser',
            sortable: true,
          },
          {
            key: 'IsActive',
            label: 'Active',
            sortable: true,
          },
          {
            key: 'LastLoginDateTime',
            label: 'Last login',
            sortable: true,
          },
          {
            key: 'actions',
            label: 'Actions',
            class: 'link-icons',
          },
        ],
      }
    },
    mounted () {
    }
  }
</script>

<style scoped>

</style>